import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./App.css";
import "./scss/index.scss";
import { ZoomMtg } from "@zoomus/websdk";
import { useParams } from "react-router-dom";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import ImagePreview from "./ImagePreview";
import "react-html5-camera-photo/build/css/index.css";

import Feedback from "./Feedback";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.12.2/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("vi-VN");
ZoomMtg.i18n.reload("vi-VN");

function Customer2() {
  const params: { hash: string } = useParams();
  const { hash } = params;
  const [userInfo, setUserInfo] = useState<any>();

  const [modalFeedback, setModalFeed] = useState<boolean>(false);

  const [statusMeeting, setStatusMeeting] = useState<any>("");
  const [uuidZoom, setuuidZoom] = useState<any>("");

  const [dataUri, setDataUri] = useState("");
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [frontCamera, setFrontCamera] = useState<any>(FACING_MODES.USER);
  const [typeCamera, setTypeCamera] = useState("");
  const [counter, setCounter] = useState(15);
  const delay = 1;
  const timer = useRef<any>(null);
  // check location

  const [, setMsgSocket] = useState<any>([]);

  const ws = useRef<any>(null);

  // var url = `ws://118.70.109.34:1999/ws/socket-server/?videocall=${hash}`;

  var getInfoEndpoint = `${process.env.REACT_APP_API_URL}api/videocall/customer/join/`;

  const handleJoin = (hash: string) => {
    let value = { key: hash };
    axios
      .post(getInfoEndpoint, value)
      .then((res: any) => {
        if (res?.status === 200) {
          setUserInfo(res.data);
        } else {
          window.location.href = '/home';
        }
      })
      .catch((error) => {
        window.location.href = '/home';
      });
  };

  useEffect(() => {
    if (hash) {
      let url = `${process.env.REACT_APP_API_VIDEO_WSS}/ws/socket-server/?videocall=${hash}`;
      ws.current = new WebSocket(url);
      ws.current.onmessage = (e: any) => {
        const message = JSON.parse(e.data);
        if (message?.message === "Take Photo") {
          setOpenCamera(true);
          setTypeCamera(message?.source);
          setuuidZoom(message?.org);
          // textInput.current.focus();
        }
        // if (message?.name === "rear" && message?.message === "Take Photo") {
        //   setFrontCamera(FACING_MODES.ENVIRONMENT);
        // } else {
        //   setFrontCamera(FACING_MODES.USER);
        // }
        setMsgSocket((prev: any) => [...prev, message]);
      };

      handleJoin(hash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  // var sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;

  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = "";

  function handleClickCamera(): void {
    let el = document.querySelector(
      ".send-video-container__btn"
    ) as HTMLDivElement | null;
    el?.click();
    if (el) {
      el.addEventListener("click", function () {
        setTimeout(() => {
          showElement();
        }, 1500);
      });
    }
  }

  function getAudioAutomatic(): void {
    setTimeout(joinAudioAuto, 1000);
    for (var i = 0; i < 2; i++) handleClickCamera();
  }

  function joinAudioAuto(): void {
    let audioBtn = document.querySelector(
      ".join-audio-by-voip__join-btn"
    ) as HTMLDivElement | null;
    if (audioBtn) {
      audioBtn.click();
    } else {
      openJoinAudio();
    }
  }
  function openJoinAudio(): void {
    let btn = document.getElementsByClassName(
      "join-audio-container__btn"
    )[0] as HTMLDivElement | null;

    let joinDialog = document.getElementsByClassName(
      "join-dialog"
    )[0] as HTMLDivElement | null;

    if (btn && !joinDialog) {
      btn.click();
    }
    return;
  }
//send message when leave

  function handleCloseMeeting() {
    let arrayBtn = Array.from(
      document.querySelectorAll("button.footer__leave-btn")
    );
    arrayBtn?.forEach((element) => {      
        element.addEventListener("click", function () {
          ZoomMtg.leaveMeeting({
            success: function(res:any) {
              ws.current.send(
                JSON.stringify({
                  message: 'customer leave meeting',
                  sender: '',
                  org: '',
                  source: '',
                  recipient: '',
                  name: '',
                  send_to: '',
                })
              );
            },
            error: function(res:any) {
            }
        });
        });
      
    });
  }
  // Appending newly created div element to the root element.
  const elementRef = useRef(document.createElement("div"));
  const showElement = React.useCallback(() => {
    let root = document.querySelector("#videoOptionMenu") as HTMLDivElement;
    root?.appendChild(elementRef.current);
  }, []);

  function startMeeting(signature: any) {
    document.getElementById("zmmtg-root")!.style.display = "block";

    ZoomMtg.init({
      leaveUrl: `${process.env.REACT_APP_BACK_ZOOM}`,
      disableJoinAudio: false, //optional
      isSupportAV: true,
      disablePreview: true,
      success: (success: any) => {
        //console.log(success);
        ZoomMtg.join({
          signature: userInfo.signature,
          meetingNumber: userInfo.meetingNumber,
          userName: userInfo.phone,
          sdkKey: userInfo.sdkey,
          userEmail: "",
          passWord: userInfo.passWord,
          tk: registrantToken,
          success: (success: any) => {
            //console.log(success);

            if (success.method === "join") {
              setTimeout(() => {
                getAudioAutomatic();
                closePopup();
              }, 3000);

              ws.current.send(
                JSON.stringify({
                  message: "Recording started",
                  sender: "",
                  org: "",
                  source: "",
                  recipient: "",
                  name: "",
                  send_to: "",
                })
              );
              ws.current.send(
                JSON.stringify({
                  message: "Allow Take Photo Customer",
                  sender: "",
                  org: "",
                  source: "",
                  recipient: "",
                  name: "",
                  send_to: "",
                })
              );
              // handleClickCamera();

              // setTimeout(closePopupAudio, 1000);

              ZoomMtg.getCurrentMeetingInfo({
                success: function (res: any) {
                  setuuidZoom(res.result.mid);
                  // console.log("Metting info", res?.result?.mid);
                },
              });
              ZoomMtg.inMeetingServiceListener(
                "onMeetingStatus",
                function (data: any) {
                  setStatusMeeting(data.meetingStatus);
                }
              );
              handleCloseMeeting()
            }
          },
          error: (error: any) => {
            //console.log(error);
          },
        });
      },
      error: (error: any) => {
        //console.log(error);
      },
    });
  }

  useEffect(() => {
    if (statusMeeting === 3) {
      setModalFeed(true);
    }
  }, [statusMeeting]);

  useEffect(() => {
    if (userInfo) {
      startMeeting(userInfo.signature);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleGetLocation = async () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const newUserPos = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      };
      ws.current.send(
        JSON.stringify({
          message: "Share Geolocation",
          sender: "",
          org: "",
          source: "",
          recipient: "",
          name: "",
          send_to: "",
        })
      );

      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/videocall/customer/update/${hash}`,
          newUserPos
        )
        .then((res) => {})
        .catch((error) => {
          console.error(error);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function handleTakePhotoAnimationDone(dataUri: any) {
    // Do stuff with the photo...
    setDataUri(dataUri);
  }

  function b64toBlob(dataURI: any, filename: any) {
    var arr = dataURI.split(","),
      mime = "image/jpg",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const handleSubmit = async () => {
    // generate file from base64 string
    var convertedFile = b64toBlob(
      dataUri,
      `random_${new Date().valueOf()}.jpg`
    );
    // let image = b64toBlob(dataUri)

    let formData = new FormData();
    formData.append("image", convertedFile);
    formData.append("type", typeCamera);
    formData.append("cam", frontCamera === "user" ? "f" : "b");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/videocall/upload/${hash}`,
        formData
      )
      .then((res) => {
        ws.current.send(
          JSON.stringify({
            message: "success upload",
            sender: "",
            org: "",
            source: "",
            recipient: "",
            name: "photo",
            send_to: "",
          })
        );
        setOpenCamera(false);
        setDataUri("");
        const timeoutID = setTimeout(() => {
          for (let i = 0; i < 2; i++) handleClickCamera();
        }, 1000);
        return () => {
          // 👇️ clear timeout when the component unmounts
          clearTimeout(timeoutID);
        };

        //getLocationCustomer()
      })
      .catch((error) => {
        setOpenCamera(false);
        setDataUri("");
        const timeoutID = setTimeout(() => {
          for (let i = 0; i < 2; i++) handleClickCamera();
        }, 1000);
        return () => {
          // 👇️ clear timeout when the component unmounts
          clearTimeout(timeoutID);
        };
      });
  };

  // close popup
  function closePopup(): void {
    handleGetLocation();

    let button = document.querySelector(".zm-btn-legacy" ) as HTMLDivElement | null;
    if (button?.textContent === "Đã hiểu") {
      button?.click();
      setTimeout(() => {
        for (var i = 0; i < 2; i++) handleClickCamera();
      }, 3000);
    } else {
      setTimeout(() => {
        for (var i = 0; i < 2; i++) handleClickCamera();
      }, 3000);
    }

  }

  // automatic takephoto

  function takePhoto(): void {
    let btnTakePhoto = document.getElementById("inner-circle");
    btnTakePhoto?.click();
  }

  useEffect(() => {
    if (openCamera === true && counter > 0) {
      // useRef value stored in .current property

      timer.current = setInterval(() => setCounter((v) => v - 1), delay * 1000);

      // clear on component unmount
      return () => {
        clearInterval(timer.current);
      };
    }
    if (openCamera === true && counter === 0) {
      takePhoto();
    }
    // if(counter ) return
    if (openCamera === false) {
      setCounter(15);
    }
  }, [openCamera, counter]);

  useEffect(() => {
    if (dataUri) {
      // getSuccess();
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUri]);
  const handleSwitchCamera = () => {
    setFrontCamera((prevState: any) =>
      prevState === FACING_MODES.USER
        ? FACING_MODES.ENVIRONMENT
        : FACING_MODES.USER
    );
  };
  const handleCloseTakePhoto = () => {
    setOpenCamera(false);
    setTimeout(() => {
      for (var i = 0; i < 2; i++) handleClickCamera();
    }, 1000);
  };
  return (
    <div className="App">
      {modalFeedback && <Feedback hash={userInfo.phone} uuidZoom={uuidZoom} />}
      {dataUri && <ImagePreview dataUri={dataUri} />}
      <main>
        {ReactDOM.createPortal(
          <>
            <span className="custom-camera">
              <i className="fa fa-refresh" style={{ fontSize: 20 }}></i>
            </span>
          </>,
          elementRef.current
        )}
        <div className={`box-camera ${openCamera ? "active" : ""}`}>
          {openCamera && (
            <>
              <div className="text-photo">
                Ảnh đang được chụp. Xin chờ trong giây lát...
              </div>
              <div className="text-counter">{counter}</div>
              <Camera
                idealFacingMode={frontCamera}
                onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                imageType={IMAGE_TYPES.JPG}
                isMaxResolution={true}
                isImageMirror={false}
                isSilentMode={true}
                imageCompression={0.5}
              />
              {/* <div  className='btn btn-switch' onClick={() => handleSwitchCamera()}>
                  <i className='fa fa-refresh'></i>
                </div> */}

              {/* <div className='btn btn-switch' style={{ marginTop: 50 }} onClick={() => handleCloseTakePhoto()}>
                  <i className='fa fa-close'></i>
                </div> */}
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default Customer2;
