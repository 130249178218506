import React, { useEffect, useState } from "react";
import axios from "axios";

const Feedback = ({hash, uuidZoom}:any) => {
  // console.log(uuidZoom)
  const [dataFeed, setDataFeed] = useState<any>("");

  const [dataSubmit, setDataSubmit] = useState<{
    status: string;
    description:string;
    uuid:string;
  }>({
    status:'1',
    description:'',
    uuid:uuidZoom,
  });


//   let token = localStorage.getItem("access_token");
   let axiosConfig = {
     headers: {
       "Content-Type": "application/json",
       //Authorization: `Token ${token}`,
     },
   };

  const getFeedStatus = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/videocall/feedback-customer/${hash}`,axiosConfig)
      .then((res) => {
        setDataFeed(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getFeedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setDataSubmit({ ...dataSubmit, [name]: value });
  };

  const onChange = (e:any) => {
    const { name, value } = e.target;
    setDataSubmit({ ...dataSubmit, [name]: value });
  }

  const handleSubmitFeed = () => {
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/videocall/feedback-customer/${hash}`,
        dataSubmit,
        axiosConfig
      )
      .then((res) => {
        // console.log(res)
        window.location.href = `${process.env.REACT_APP_BACK_ZOOM}`
      })
      .catch((error) => {
        console.error(error);
        window.location.href = `${process.env.REACT_APP_BACK_ZOOM}`

      });
  };
  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal-popup_style2 open" style={{background:'#000'}}>
        <div className="popup-container pu-feedback">
          <div className="popup-close">
            <span className="fa fa-close"></span>
          </div>
          <div className="content">
            <div className="box-form">
              <h3 className="title text-center" style={{fontSize:16,lineHeight:'24px'}}>
                Anh/chị có hài lòng về chất lượng thẩm định của chúng tôi không?
              </h3>
              <div className="form-input">
                <div className="label-name">Chọn nội dung đánh giá</div>
                <select className="form-select"
                name="status"
                value={dataSubmit.status}
                onChange={onChange}>
                
                  {dataFeed &&
                    dataFeed.map((item: any, index: number) => {
                      return <option key={index} value={item.id}>{item.name}</option>;
                    })}
                </select>
              </div>
              {
                dataSubmit.status !== '1' && (
                <div className="form-input">
                <div className="label-name">Nội dung</div>

                <textarea
                  onChange={handleChangeInput}
                  name="description"
                  className="form-control"
                  value={dataSubmit.description}
                ></textarea>
              </div>
                )
              }
              
              <div className="form-input text-right">
                <div className="btn" onClick={()=>handleSubmitFeed()}>Gửi</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
