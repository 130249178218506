import React from 'react';
import { Link } from 'react-router-dom';
export const Home = () => {
  
    return (
      <>
      <div className="header header-sticky">
      <div className="container">
        <div className="logo">
        <Link to={'/khach-hang/'}>
        <span className="logo-icon"></span>
            </Link>
        </div>
        <ul className="navbar_menu pushmenu js-menubar">
          <li className="level1">
            <a href="#1" title="">Trang chủ</a>
          </li>
          <li className="level1">
            <a href="#2" title="">Giới thiệu</a>
          </li>
          <li className="level1">
            <a href="#3" title="">Tuyển dụng</a>
          </li>
        </ul>
      </div>
    </div>
    <div className="section-banner">
      <div className="photo">
       <img src="https://scontent.fhan14-2.fna.fbcdn.net/v/t39.30808-6/275159209_129271769634025_6715684183217749639_n.png?_nc_cat=106&ccb=1-7&_nc_sid=e3f864&_nc_ohc=p_sjbv9QaEkAX-PrZ1m&_nc_ht=scontent.fhan14-2.fna&oh=00_AfAEZO6N2oaRFD0fyCyAiGLDXH3peGp-l55brjkWKWSegQ&oe=64AA922C" alt='abc' />
      </div>
      </div>
    
    </>
    );
  };

  
  export default Home;